import { useState } from 'react';

import placeholder from '../../../../assets/img/placeholder/game-cover.png';
import { SegmentedControl } from '../../../common/SegmentedControl';
import { type Option } from '../../../common/Utilities';
import { ModalWrapper } from '../../../ConfirmCancelModalContext/ModalWrapper';
import { InlineLoadingSpinner } from '../../../MediaSearch/MediaSearch';
import {
  OrganizerMultiSelect,
  type OrganizerSelectOption,
} from '../../../Organization/OrganizerSelect';
import { useUser } from '../../../UserContext';
import {
  LearningGroupMultiSelect,
  type LearningGroupOption,
} from '../Components/LearningGroupMultiSelect';

type AssignmentMode = 'email' | 'group';

export interface EnrollmentTarget {
  type: 'course' | 'stack';
  id: string;
  name: string;
  cover?: string;
}

interface EnrollmentModalProps {
  enrollmentTarget: EnrollmentTarget;
  onClose: () => void;
  onAssigned: (params: {
    mode: AssignmentMode;
    items: string[];
    createGroup?: boolean;
    newGroupName?: string;
  }) => Promise<void>;
}

export function EnrollmentModal({
  enrollmentTarget,
  onClose,
  onAssigned,
}: EnrollmentModalProps) {
  const coverSrc = enrollmentTarget.cover || placeholder;

  const modeOptions: Option<AssignmentMode>[] = [
    { label: 'Invite by Email', value: 'email' },
    { label: 'Assign by Group Name', value: 'group' },
  ];
  const [selectedMode, setSelectedMode] = useState<Option<AssignmentMode>>(
    modeOptions[0]
  );

  const [emailOptions, setEmailOptions] = useState<OrganizerSelectOption[]>([]);
  const [groupOptions, setGroupOptions] = useState<LearningGroupOption[]>([]);
  const [createGroup, setCreateGroup] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [loading, setLoading] = useState(false);
  const user = useUser();
  const orgId = user.organizer?.orgId;

  const handleAssign = async () => {
    if (selectedMode.value === 'email') {
      const emails = emailOptions.map((opt) => {
        if (opt.kind === 'organizer') {
          return opt.organizer.email;
        } else {
          return opt.value;
        }
      });

      if (emails.length === 0) return;
      try {
        setLoading(true);
        await onAssigned({
          mode: 'email',
          items: emails,
          createGroup,
          newGroupName: createGroup ? newGroupName : undefined,
        });
      } finally {
        setLoading(false);
      }
    } else {
      const items = groupOptions.map((opt) => opt.value);
      try {
        setLoading(true);
        await onAssigned({
          mode: 'group',
          items,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const isDisabled =
    (selectedMode.value === 'email' && emailOptions.length === 0) ||
    (selectedMode.value === 'group' && groupOptions.length === 0);

  return (
    <ModalWrapper
      borderStyle='gray'
      containerClassName='w-[600px] h-[550px]'
      innerClassName='bg-main-layer flex flex-col'
      onClose={onClose}
    >
      <>
        <div className='px-8 pt-8 text-white flex flex-col gap-4 flex-grow'>
          {/* Header with Cover and Name */}
          <div className='flex items-center gap-4 self-center'>
            <div className='w-34 h-20 rounded-lg overflow-hidden'>
              <img
                src={coverSrc}
                alt={enrollmentTarget.name}
                className='w-full h-full object-cover'
              />
            </div>
            <div className='text-2xl font-bold uppercase'>
              {enrollmentTarget.name}
            </div>
          </div>

          {/* Mode Toggle using SegmentedControl */}
          <div className='w-full flex justify-center'>
            <div className='w-76'>
              <SegmentedControl
                options={modeOptions}
                value={selectedMode}
                onChange={setSelectedMode}
                styles={{
                  glider: 'bg-lp-gray-006 rounded-lg',
                  track: 'rounded-xl p-1 border border-secondary',
                  option: 'px-4 py-2 transition-colors text-sms',
                  selectedOption: 'text-white',
                  unselectedOption: 'text-gray-400 hover:text-white',
                }}
              />
            </div>
          </div>

          {selectedMode.value === 'email' && (
            <div className='flex flex-col gap-2'>
              <OrganizerMultiSelect
                orgId={orgId ?? ''}
                options={emailOptions}
                onChange={setEmailOptions}
                creatable
                placeholder='Enter one or more email addresses'
                className='w-full'
                scrollable={{ maxHeight: 100 }}
                maxMenuHeight={150}
              />
              <div className='flex items-center gap-2 mt-2'>
                <input
                  id='createGroup'
                  type='checkbox'
                  className='checkbox-dark'
                  checked={createGroup}
                  onChange={(e) => setCreateGroup(e.target.checked)}
                />
                <label htmlFor='createGroup' className='text-sms'>
                  Create a new group from these emails?
                </label>
              </div>
              <div
                className={`flex flex-col gap-1 ${
                  createGroup ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <label className='text-sms font-medium text-icon-gray'>
                  Group Name
                </label>
                <input
                  type='text'
                  className='field h-10'
                  placeholder='e.g. My Onboarding Group'
                  value={newGroupName}
                  onChange={(e) => setNewGroupName(e.target.value)}
                />
              </div>
            </div>
          )}

          {selectedMode.value === 'group' && (
            <div className='flex flex-col gap-2'>
              <LearningGroupMultiSelect
                options={groupOptions}
                onChange={setGroupOptions}
                placeholder='Enter or search group names...'
              />
            </div>
          )}
        </div>

        {/* Footer */}
        <div className='mt-auto px-8 pb-8'>
          <p className='text-sms text-icon-gray text-center mb-4'>
            Assigned members will receive an email giving them access to the{' '}
            {enrollmentTarget.type} and will see it on their home page.
          </p>

          <div className='flex justify-center gap-4'>
            <button
              type='button'
              onClick={onClose}
              className='btn btn-secondary w-40 h-10'
            >
              Cancel
            </button>
            <button
              type='button'
              onClick={handleAssign}
              className='btn btn-primary w-40 h-10 flex items-center justify-center'
              disabled={isDisabled}
            >
              {loading ? (
                <InlineLoadingSpinner isUploading text='Assigning' />
              ) : (
                'Assign'
              )}
            </button>
          </div>
        </div>
      </>
    </ModalWrapper>
  );
}
